<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="animated fadeIn p-4">
		<b-row>
			<span
				class="text-muted"
				v-html="translate('summary_exchange_rates', {url: exchangeRateUrl, name:exchangeRateService})" />
			<table
				v-if="!!Object.keys(rates).length"
				class="table mt-3">
				<tbody>
					<tr
						v-if="!['sm','xs'].includes(windowWidth)"
						class="row d-flex">
						<td class="col-md-5 borderless" />
						<td class="col-md-5 borderless">
							<span class="font-weight-bold">{{ translate(globalCurrency) }}</span>
						</td>
						<td class="col-md-2 borderless" />
					</tr>
					<editable-field
						v-for="key in Object.keys(rates)"
						:key="`rates.${key}`"
						:key-value="rates[key]"
						:field-information="{editable: $can('exchange_rates', 'update'), value:rates[key], showable:['sm','xs'].includes(windowWidth) ? `${rates[key]} ${globalCurrency}` : rates[key]}"
						:field-name="translate(key)"
						:first-item="['sm','xs'].includes(windowWidth) ? translate(Object.keys(rates)[0]) : ''"
						@clearField="clearError(`rates.${key}`)"
						@editField="editProfile($event, key)" />
				</tbody>
			</table>
		</b-row>
	</div>
</template>

<script>
import { Settings as messages } from '@/translations';
import Settings from '@/util/Settings';
import EditableField from '@/components/EditableField';
import WindowSizes from '@/mixins/WindowSizes';

export default {
	name: 'ExchangeRates',
	messages,
	components: { EditableField },
	mixins: [WindowSizes],
	data() {
		return {
			settings: new Settings(),
			alert: new this.$Alert(),
			rates: {},
			globalCurrency: '',
			exchangeRateService: '',
			exchangeRateUrl: '',
		};
	},
	computed: {
		errors() {
			return this.settings.data.errors;
		},
		loading() {
			return !!this.settings.data.loading;
		},
	},
	mounted() {
		this.getRates();
	},
	methods: {
		hasError(field) {
			if (typeof this.errors.errors[field] !== 'undefined') {
				return true;
			}
			return false;
		},
		clearError(field) {
			if (field) {
				delete this.settings.data.errors.errors[field];
				this.settings.data.errors.errors = {

					...this.errors.errors,
				};
			}
		},
		saveRates(payload) {
			this.settings.updateExchangeRates(payload).then(() => {
				this.alert.toast('success', this.translate('submit_success'));
				this.getRates();
			}).catch(() => {
				this.data = payload;

				let errorName = 'default_error_message';
				if (this.hasError('password')) {
					errorName = 'incorrect_password';
				}
				this.alert.toast('error', this.translate(errorName));
			}).finally(() => {
				this.settings.data.loading = false;
			});
		},
		editProfile(fieldValue, id) {
			const trans = {
				title: this.translate('edit_settings'),
				passwordTitle: this.translate('password'),
				notesTitle: this.translate('notes'),
			};

			const attributes = {
				emptyPasswordMessage: this.translate('password_required'),
			};

			this.alert.confirmationWithPasswordAndNotes(trans.title, trans.passwordTitle, trans.notesTitle, attributes).then((response) => {
				const { value } = response;
				const payload = {
					password: value.password,
					notes: value.notes,
					rates: {},
				};
				payload.rates[id] = fieldValue;
				this.saveRates(payload);
			}).catch(() => {});
		},
		getRates() {
			this.settings.getExchangeRates().then((response) => {
				this.rates = {};
				this.globalCurrency = response.meta.global_currency;
				this.exchangeRateService = response.meta.exchange_rates_service;
				this.exchangeRateUrl = response.meta.exchange_rates_url;
				response.response.rates.forEach((item) => {
					this.rates[item.currency_from] = item.rate;
				});
			});
		},
	},
};
</script>

<style scoped>
	.table td.borderless {
		border: 0px !important;
	}
</style>
